
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/vendor/rfs";

// Initialize
@import "variables";
@import "mixins";


// Core CSS
@import "reset";
@import "helpers";
@import "layouts";


// Content Blocks
@import "header";
@import "elements";
@import "footer";
@import "extras";

#contact {
    padding-bottom: 280px;  /* Add padding to make sure the section is scrollable */
  }
  